<template>
  <el-upload
    action=""
    :class="{'upload-danger': this.isDanger}"
    drag
    :on-change="onChange"
    :file-list="fileList"
    :accept="accept"
    :auto-upload="false"
  >
    <i class="upload-icon"></i>
    <div v-if="text" class="el-upload__text" v-html="text"></div>
    <div v-if="tips" class="el-upload__tip" slot="tip" v-html="tips"></div>
  </el-upload>
</template>

<script>
export default {
  name: 'UploadWrapper',
  props: {
    accept: {
      type: String,
      default: '.csv'
    },
    isDanger: {
      type: Boolean,
      default: false
    },
    fileList: {
      type: Array,
      default: () => []
    },
    text: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: ''
    }
  },
  methods: {
    onChange (data) {
      this.$emit('on-change', data)
    }
  }
}
</script>
