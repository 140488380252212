import { keys } from 'lodash'
import api from '@/api'
import i18n from '@/i18n'

export default class UploadService {
  constructor (form, options = {}) {
    this.formData = this.initializeFormData(form)
    this.options = options
  }

  async upload (showModal = () => {}) {
    const requestId = await this.handleUpload()
    const { validationErrors } = await this.handleValidate(requestId)
    if (validationErrors && validationErrors.length) {
      return validationErrors
    } else {
      await this.handleProcess(requestId, showModal)
      return []
    }
  }

  initializeFormData (object) {
    const formData = new FormData()
    keys(object).forEach(key => {
      formData.append(key, object[key])
    })
    return formData
  }

  handleUpload () {
    return api.upload.upload(this.formData, this.options)
  }

  handleValidate (requestId) {
    return api.upload.validate(requestId, this.options)
  }

  handleProcess (requestId, showModal) {
    return api.upload.process(requestId, {
      ...this.options,
      successNotification: i18n.t('uploads.success_notification'),
      onSuccess: res => showModal(res)
    })
  }
}
