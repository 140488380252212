import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'
import UploadService from '@/app/services/UploadService/UploadService'
import UploadWrapper from '@/components/Backoffice/Uploads/UploadWrapper'
import UploadErrorTable from '@/components/Backoffice/Uploads/UploadErrorTable'
import UploadSuccessModal from '@/components/Backoffice/Uploads/UploadSuccessModal'
import UploadSuccessNmbsModal from '@/components/Backoffice/Uploads/UploadSuccessNmbsModal'

export default {
  name: 'BackofficeUpload',
  mixins: [ConfirmBeforeRouterLeave],
  components: {
    UploadWrapper,
    UploadErrorTable,
    UploadSuccessModal,
    UploadSuccessNmbsModal
  },
  data () {
    return {
      isSuccessModalVisible: false,
      successModaldata: {},
      fileList: [],
      key: null,
      form: {},
      loading: false,
      saving: false,
      saveDisabled: true,
      dirty: false,
      validationErrors: []
    }
  },
  watch: {
    fileList: {
      deep: true,
      handler () {
        this.validationErrors = []
        this.key = this.$key()
      }
    }
  },
  computed: {
    isModalVisible () {
      return this.isSuccessModalVisible
    }
  },
  methods: {
    handleUpload (fileRequest) {
      this.fileList = [fileRequest]
    },
    showSuccessModal (data) {
      this.successModaldata = {
        ...data,
        errors: JSON.stringify(data.errors),
        linesWithErrors: JSON.stringify(data.linesWithErrors)
      }
      if (data.summary && data.summary.failedRecords && Array.isArray(data.summary.failedRecords)) {
        this.successModaldata.failedRecordsAmount = data.summary.failedRecords.length
        this.successModaldata.failedRecords = JSON.stringify(data.summary.failedRecords)
      }
      this.isSuccessModalVisible = true
    },
    modalClose () {
      this.isSuccessModalVisible = false
    },
    async handleSaveChanges () {
      this.saving = true
      this.validationErrors = []
      const form = this.getForm()
      const uploader = this.getUploader(form)
      try {
        this.validationErrors = await uploader.upload(this.showSuccessModal)
      } catch (e) {
        console.log('import error ', e)
      }
      if (!this.validationErrors) {
        this.fileList = []
      } else {
        this.$scrollTo('#uploadErrorTable')
      }
      this.saving = false
      this.key = this.$key()
    },
    getUploader (form) {
      return new UploadService(form)
    },
    getForm () {
      return {
        file: this.fileList[0].raw
      }
    }
  },
  mounted () {
    this.key = this.$key()
    this.$watch('fileList', {
      deep: true,
      handler () {
        this.dirty = true
      }
    })
  }
}
