<template>
  <base-section v-if="validationErrors.length">
    <div v-if="title" class="info">
      <div class="title error" v-html="title"/>
    </div>
    <el-table
      row-class-name="table-row"
      :data="validationErrors"
      style="max-width: 900px;"
    >
      <slot>
        <el-table-column
          prop="fieldInError"
          :label="$t('user_management.import_users_page.field')"
          sortable
        ></el-table-column>
        <el-table-column
          prop="reason"
          :label="$t('user_management.import_users_page.reason')"
          sortable
          :min-width="150">
        </el-table-column>
        <el-table-column
          prop="sourceLineNumber"
          :label="$t('uploads.upload_error_table.line_number')"
          sortable
          :width="80">
        </el-table-column>
      </slot>
    </el-table>
  </base-section>
</template>

<script>
export default {
  name: 'UploadErrorTable',
  props: {
    title: {
      type: String,
      default: ''
    },
    validationErrors: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
.error {
      color: #BD0D4D;
    }
</style>
