<template>
  <el-dialog
    :title="$t('backoffice.uploads.modal', { type: title })"
    :close-on-click-modal="false"
    :visible.sync="isVisible"
    @close="modalClose"
  >
    <el-form>
      <p>
        <span class="counterTotal">{{ data.totalProcessed }}</span> {{ this.$t('backoffice.uploads.counter') }}
      </p>
      <base-input readonly label="errors" :value="data.errors" />
      <base-input readonly label="Line with errors" :value="data.linesWithErrors" />
      <base-input readonly label="Total processed" :value="data.totalProcessed" />
      <base-input readonly label="Total with errors" :value="data.totalWithErrors" />
      <base-input readonly label="Total without errors" :value="data.totalWithoutErrors" />
      <base-input
        v-if="data.failedRecordsAmount"
        readonly
        label="Total skipped records"
        :value="data.failedRecordsAmount"
      />
      <base-input v-if="data.failedRecords" readonly label="Skipped records" :value="data.failedRecords" />
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'UploadSuccessModal',
  props: {
    title: {
      type: String,
      default: 'Upload success'
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    },
    modalClose: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.counterTotal {
  color: #139cdc;
}
</style>
