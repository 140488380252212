<template>
  <el-dialog
    :title="$t('backoffice.uploads.modal', { type: title })"
    :close-on-click-modal="false"
    :visible.sync="isVisible"
    @close="modalClose"
    width="75%"
    top="8vh"
  >
    <el-form>
      <p>
        <span class="counterTotal">{{ data.totalProcessed }}</span>
        {{ this.$t('backoffice.uploads.counter') }}
      </p>
      <br />
      <base-section title="Errors:" noPadding :withBorder="false">
        {{ data.errors }}
      </base-section>
      <base-section title="Line with errors:" noPadding :withBorder="false">
        {{ data.linesWithErrors }}
      </base-section>
      <base-section title="Total processed:" noPadding :withBorder="false">
        {{ data.totalProcessed }}
      </base-section>
      <base-section title="Total with errors:" noPadding :withBorder="false">
        {{ data.totalWithErrors }}
      </base-section>
      <base-section title="Total without errors:" noPadding :withBorder="false">
        {{ data.totalWithoutErrors }}
      </base-section>
      <template v-if="this.data.summary">
        <base-section
          title="Total amount in NMBS report:"
          noPadding
          :withBorder="false"
        >
          {{ data.summary.totalAmountInNmbsReport }}
        </base-section>
        <base-section
          title="Total amount in ticket store:"
          noPadding
          :withBorder="false"
        >
          {{ data.summary.totalAmountInTicketStore }}
        </base-section>
        <base-section
          title="Total tickets in NMBS report:"
          noPadding
          :withBorder="false"
        >
          {{ data.summary.totalTicketsInNmbsReport }}
        </base-section>
        <base-section
          title="Total tickets in ticket store:"
          noPadding
          :withBorder="false"
        >
          {{ data.summary.totalTicketsInTicketStore }}
        </base-section>
        <base-section
          title="Transaction IDs not in NMBS report:"
          noPadding
          :withBorder="false"
        >
          {{ data.summary.transactionIdsNotInNmbsReport.join(', ') }}
        </base-section>
        <base-section
          title="Transaction IDs not in ticket store:"
          noPadding
          :withBorder="false"
        >
          {{ data.summary.transactionIdsNotInTicketStore.join(', ') }}
        </base-section>
        <base-section
          title="Transaction IDs with different price:"
          noPadding
          :withBorder="false"
        >
          {{ data.summary.transactionIdsWithDifferentPrice.join(', ') }}
        </base-section>
      </template>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'UploadSuccessNmbsModal',
  props: {
    title: {
      type: String,
      default: 'Upload success'
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    },
    modalClose: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss">
.counterTotal {
  color: #139cdc;
}
.base-section__title {
  width: 300px;
}
</style>
